import { Play } from '@/icons';
import { getVideoData } from '@/services/video';
import { FAULT_IMAGE, MEDIA_KEY_ENUM } from '@/constants/const';
import { App, Image, ImageProps, Modal, Spin } from '@antd';
import { useBoolean, useSafeState } from 'ahooks';
import { useEffect, useRef } from 'react';
import Player from 'xgplayer';
import HlsPlayer from 'xgplayer-hls';
import 'xgplayer/dist/index.min.css';
import 'xgplayer/es/plugins/danmu/index.css';
import style from './index.less';
import { VideoCameraOutlined } from '@ant-design/icons';
import { dict } from '@/hooks/useChangeLocale';

type VideoCoverImgProps = ImageProps & {
  itemId?: string; // itemId的目的是为了查询播放地址,可以查询平台地址
  platformPlayUrl?: string; //平台跳转地址
  mediaId?: MEDIA_KEY_ENUM;
};

export const VideoCoverImg = ({ itemId, platformPlayUrl, mediaId, ...props }: VideoCoverImgProps) => {
  const { message } = App.useApp();
  const [loading, { setTrue: setLoading, setFalse: hideLoading }] = useBoolean(false);
  const [shareUrl, setShareUrl] = useSafeState('');
  const [isOssURL, setIsOssUrl] = useSafeState(false);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const videoContainerRef = useRef<any>();

  const openPlatformVideo = async () => {
    if (platformPlayUrl) {
      window.open(platformPlayUrl);
      return;
    }

    let url = shareUrl;
    let isOSSTempUrl = false;

    try {
      setLoading();
      const { list } = await getVideoData(itemId);
      const data = list[0];
      isOSSTempUrl = !!data.share_oss_url;
      setIsOssUrl(isOSSTempUrl);
      url = data.share_oss_url || data.share_url;
      setShareUrl(url);
    } catch (error) {
      message.warning('浏览器暂不支持该视频的播放！');
      return;
    } finally {
      hideLoading();
    }

    if (isOSSTempUrl) {
      showModal(); //本地播放
    } else {
      window.open(url);
    }
  };

  useEffect(() => {
    if (!shareUrl || !isOssURL) return;
    const player = new Player({
      el: videoContainerRef.current,
      autoplay: true,
      url: shareUrl,
      poster: props.src,
    });
    return () => player.offAll();
  }, [shareUrl, isOssURL]);

  return (
    <>
      <div
        className={style['video-cover-img-wrap']}
        title={dict('CLICK_TO_PC_WATCH_VIDEO')}
        style={props.style}
        onClick={openPlatformVideo}
      >
        <Spin spinning={loading}>
          <Image
            preview={false}
            style={{ minHeight: 80 }}
            fallback={FAULT_IMAGE}
            // loading="lazy"
            referrerPolicy="same-origin"
            {...props}
          />
        </Spin>
        <Play />
      </div>
      <Modal
        title={dict('PLAY_VIDEO')}
        width={650}
        open={isModalOpen}
        onCancel={hideModal}
        // destroyOnClose
        okButtonProps={{ style: { display: 'none' } }}
        // cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div ref={videoContainerRef} style={{ display: 'inline-block' }} />
      </Modal>
    </>
  );
};

export type LiveVideoCoverImgProps = {
  replayUrl?: string;
} & ImageProps;

export const LiveVideoCoverImg = ({ replayUrl, ...props }: LiveVideoCoverImgProps) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const videoContainerRef = useRef<any>();
  const src = props.src || FAULT_IMAGE;

  const openVideo = async () => {
    setTimeout(() => {
      new Player({
        el: videoContainerRef.current,
        autoplay: false,
        url: replayUrl?.split('?')[0],
        poster: props.src,
        plugins: [HlsPlayer],
      });
    }, 100);

    showModal();
  };

  return (
    <>
      <div
        style={props.style}
        className={style['livevideo-cover-img-wrap']}
        title={dict('CLICK_TO_WATCH')}
        onClick={replayUrl ? openVideo : undefined}
      >
        <Image preview={false} style={{ minHeight: 80 }} {...props} src={src} referrerPolicy="same-origin" />
        <VideoCameraOutlined />
      </div>
      <Modal
        title={dict('PLAY_HISTORY_LIVE_VIDEO')}
        width={650}
        open={isModalOpen}
        onCancel={hideModal}
        destroyOnClose
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        bodyStyle={{ justifyContent: 'center', display: 'flex' }}
      >
        <div ref={videoContainerRef} style={{ display: 'inline-block' }} />
      </Modal>
    </>
  );
};
