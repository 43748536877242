import { FAULT_IMAGE } from '@/constants/const';
import { Avatar, Space, Typography } from '@antd';
import { CSSProperties } from 'react';

const { Text } = Typography;

type AccountAvatorProps = {
  avaterUrl?: string;
  name: string;
  style?: CSSProperties;
  showName?: boolean;
};
export const AccountAvator = ({ avaterUrl, name, showName = true, style: cssStyle }: AccountAvatorProps) => {
  const url = avaterUrl ? avaterUrl || FAULT_IMAGE : undefined;
  return (
    <Space style={{ columnGap: 5, ...cssStyle }}>
      <Avatar
        size={30}
        style={{ backgroundColor: '#F8F9FA', border: '1px solid #E2E6E9', color: '#ADB2B8' }}
        src={url}
        alt="avatar"
      >
        {avaterUrl ? undefined : name?.substring(0, 1).toLocaleUpperCase()}
      </Avatar>
      {showName ? <Text style={{ fontSize: 12 }}>{name}</Text> : null}
    </Space>
  );
};
