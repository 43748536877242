import { dict } from '@/hooks/useChangeLocale';
import { QueryFilter, QueryFilterProps } from '@ant-design/pro-components';
import { Form } from '@antd';
import { useMemoizedFn } from 'ahooks';

export function TableQueryFilter<T = Record<string, any>>({
  children,
  initialValues = {},
  ...rest
}: QueryFilterProps<T>) {
  const [form] = Form.useForm<any>();

  const resetParams = useMemoizedFn(() => form.setFieldsValue(initialValues));

  return (
    <QueryFilter
      form={form}
      searchText={dict('ACTION_SEARCH')}
      initialValues={initialValues}
      {...rest}
      onReset={resetParams}
    >
      {children}
    </QueryFilter>
  );
}
