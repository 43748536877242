import { MEDIA_KEY_ENUM, defaultFormItemConfig } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { getGeoRegionTree } from '@/services/geo_region';
import { getVideoPoi } from '@/services/poi';
import { Poi } from '@/services/poi/type';
import { Button, Cascader, Divider, Form, Input, InputProps, Modal, ModalProps, Select, Space } from '@antd';
import { useBoolean, useDebounceEffect, useRequest, useResetState } from 'ahooks';
import { isEmpty } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';

type PoiListProp = {
  region?: string[];
  value?: Poi;
  mediaId: MEDIA_KEY_ENUM;
  onChange?: (value) => void;
};
const PickPoiSelect: React.FC<PoiListProp> = ({ region, value, mediaId, onChange }) => {
  const { user } = useAppContext();
  const [cursor, setCusor, resetCusor] = useResetState(0);
  const [nextCusor, setNextCusor, resetNextCusor] = useResetState(0);
  const [authId, setAuthId, resetAuthId] = useResetState<string>('');
  const [cookies, setCookies, resetCookies] = useResetState<string>('');
  const [hasMore, setHasMore, resetHasMore] = useResetState(false);
  const [keyword, setKeyword] = useState('');
  const [dataSource, setDataSource, retsetDatasource] = useResetState<any>([]);
  const [loading, { setTrue: setLoading, setFalse: hideLoading }] = useBoolean(false);

  useDebounceEffect(
    () => {
      if (isEmpty(keyword)) {
        setDataSource([]);
        hideLoading();
        return;
      }

      setLoading();
      getVideoPoi({
        city: region ? [...region].pop() : undefined,
        count: 20,
        cursor,
        keyword,
        mediaId,
        bid: user?.bid,
        authId,
        cookies,
      })
        .then((result) => {
          const { cursor: nextCusor, pois, has_more, cookies, authId } = result;
          const newData = (pois || []).map((poi) => {
            const { poi_name, province, district, city, address } = poi;
            return {
              value: poi.poi_id,
              poi,
              label: poi_name
                ? `${poi_name}（${(province || '') + (city || '') + (district || '') + (address || '')} ）`
                : address,
            };
          });

          setDataSource((prev) => [...prev, ...newData]);
          setNextCusor(nextCusor);
          setHasMore(has_more);
          setAuthId(authId || '');
          setCookies(cookies || '');
        })
        .finally(hideLoading);
    },
    [region, keyword, cursor, mediaId, user],
    { wait: 500 },
  );

  useEffect(() => {
    resetCusor();
    resetNextCusor();
    retsetDatasource();
    resetHasMore();
    resetCookies();
    resetAuthId();
  }, [region, keyword]);

  return (
    <Select
      onSearch={(keyword) => {
        setLoading();
        setKeyword(keyword);
      }}
      value={value?.poi_id}
      options={dataSource}
      loading={loading}
      placeholder={dict('SELECT_AFTER_INPUT_KEYWORD')}
      filterOption={false}
      onChange={(_, option: any) => {
        onChange?.(option?.poi);
      }}
      dropdownRender={
        hasMore
          ? (menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    type="link"
                    loading={loading}
                    onClick={() => {
                      setLoading();
                      setCusor(nextCusor);
                    }}
                  >
                    {dict('LOAD_MROE')}
                  </Button>
                </Space>
              </>
            )
          : undefined
      }
    />
  );
};

type RegionPickModalProps = {
  children: ReactNode;
  onPoiSelect: (poi: Poi) => void;
  mediaId: MEDIA_KEY_ENUM;
} & ModalProps;

const RegionPickModal: React.FC<RegionPickModalProps> = ({ children, onPoiSelect, mediaId, ...params }) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [form] = Form.useForm();
  const region = Form.useWatch('region', form);

  const { data: options = [] } = useRequest(getGeoRegionTree);

  useEffect(() => {
    if (!isModalOpen) form.resetFields();
  }, [isModalOpen]);

  return (
    <>
      <span onClick={showModal}>{children}</span>
      <Modal
        title={dict('SELECT_NAME', { name: dict('POI_POSITION') })}
        open={isModalOpen}
        onCancel={hideModal}
        destroyOnClose
        {...params}
        onOk={async () => {
          try {
            const { position } = await form.validateFields();
            onPoiSelect(position);
            hideModal();
          } catch (error) {}
        }}
      >
        <Form {...defaultFormItemConfig} labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} form={form}>
          <Form.Item name="region" label={dict('REGION_AREA')}>
            <Cascader options={options as any[]} placeholder={dict('PLEASE_SELECT')} showSearch />
          </Form.Item>
          <Form.Item
            name="position"
            label={dict('POI_POSITION')}
            required
            rules={[
              {
                required: true,
                message: dict('PLEASE_SET_POSIITON'),
              },
            ]}
          >
            <PickPoiSelect region={region} mediaId={mediaId} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

type PoiSetInputProps = {
  value?: Poi;
  onChange?: (value) => void;
  mediaId?: MEDIA_KEY_ENUM;
} & InputProps;
const PoiSetInput: React.FC<PoiSetInputProps> = ({
  value,
  onChange,
  mediaId = MEDIA_KEY_ENUM.DOUYIN,
  readOnly,
  ...param
}) => {
  const { poi_name, address, province = '', district, city, city_code } = value || {};

  const addressAll = `${(province || '') + (city || '') + (district || '') + (address || '')}`;
  const showStr = value ? (poi_name ? `${poi_name}${addressAll ? `（${addressAll}）` : ''}` : addressAll) : city_code;
  return readOnly ? (
    <span>{showStr ? showStr : '-'}</span>
  ) : (
    <RegionPickModal
      mediaId={mediaId}
      onPoiSelect={(poi) => {
        onChange?.(poi);
      }}
    >
      <Input
        {...param}
        title={showStr}
        readOnly
        value={showStr}
        placeholder={dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('POI_POSITION') })}
        addonAfter={
          value ? (
            <a
              onClick={(e) => {
                onChange?.(undefined);
                e.stopPropagation();
              }}
            >
              {dict('CLEAR')}
            </a>
          ) : undefined
        }
      />
    </RegionPickModal>
  );
};

export default PoiSetInput;
