export default () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M4.75 5.25H18.25" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M4.75 12.1992H12.25" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M17.8516 9L17.8516 19.1484L20.9127 16.1957"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.75 19.1484H12.25" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
