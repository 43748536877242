// eslint-disable-next-line no-restricted-imports
import { Select as AntdSelect, SelectProps } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import { isString } from 'lodash-es';
import React from 'react';

const filterOptionFun = (inputValue: string, option: unknown): boolean => {
  const { label, key, name, value, children } = (option as BaseOptionType) || {};
  return (
    [label, key, children, name, value]
      .filter((item) => item)
      .filter(isString)
      .findIndex((text) => text?.toLowerCase().includes(inputValue?.toLowerCase())) > -1
  );
};

export const Select: React.FC<SelectProps<unknown>> = ({ ...props }) => {
  return <AntdSelect showSearch filterOption={filterOptionFun} {...props} />;
};

export const SelectOption = AntdSelect.Option;
export const SelectOptGroup = AntdSelect.OptGroup;
export default Select;
