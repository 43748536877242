import { MEDIA_MAPPING } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import { LocalesKeys } from '@/hooks/umi';
import { dict } from '@/hooks/useChangeLocale';
import { Select, SelectProps } from '@antd';
import { isEmpty } from 'lodash';
import { Key } from 'react';

const PlatformSelect = ({
  valueType = 'id',
  excludeKeys,
  ...props
}: SelectProps & { valueType?: 'id' | 'name'; excludeKeys?: Key[] }) => {
  const { platforms } = useAppContext();

  return (
    <Select
      options={Object.entries(MEDIA_MAPPING)
        .filter(([key]) => platforms?.find((v) => v.name == key))
        .map(([key, [id, i18nText]]) => {
          const label = dict(i18nText as LocalesKeys);
          return valueType == 'id' ? { value: id, label } : { value: key, label };
        })
        .filter((option) => (isEmpty(excludeKeys) ? true : !excludeKeys?.includes(option.value)))}
      {...props}
    />
  );
};

export default PlatformSelect;
