import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { Cascader, CascaderProps } from '@antd';
import { useCreation } from 'ahooks';
import { get, isArray, last } from 'lodash';

type KeyTypes = 'value' | 'displayName' | 'name';

type EnumCascaderProps = {
  type: string;
  labelKey?: KeyTypes;
  valueKey?: KeyTypes;
  parentKey?: KeyTypes;
  flatten?: boolean;
} & CascaderProps;

export const EnumCascader = ({
  type,
  labelKey = 'displayName',
  valueKey = 'name',
  parentKey = 'value',
  value,
  onChange,
  flatten = true,
  ...props
}: EnumCascaderProps) => {
  const { getEnum } = useAppContext();

  const [treeData, enumMap] = useCreation(() => {
    const cache = {};
    const roots: any[] = [];

    const dataList = getEnum(type);
    const dataMap = dataList.reduce(
      (acc, cur) => ({
        [cur[valueKey]]: cur,
      }),
      {},
    );

    dataList.forEach((org) => {
      const curOrg = {
        ...org,
        parent: null,
        value: org[valueKey],
        label: org[labelKey],
      };
      cache[org[valueKey]] = curOrg;
      if (org[parentKey]) {
        if (!cache[org[parentKey]]) {
          const parentItem = get(dataMap, org[parentKey]);
          cache[org[parentKey]] = { ...parentItem };
        }
        cache[org[parentKey]].children = cache[org[parentKey]].children || [];
        cache[org[parentKey]].children.push(curOrg);
        curOrg.parent = cache[org[parentKey]];
      } else {
        roots.push(curOrg);
      }
    });
    return [roots, cache];
  }, [type, labelKey, valueKey, parentKey]);

  const cascaderValue = useCreation(() => {
    if (!flatten) {
      return value;
    }
    if (!isArray(value)) {
      return value;
    }
    return value.map((v) => {
      let item = enumMap[v];
      const arr = [item[valueKey]];
      while ((item = item.parent)) {
        arr.unshift(item[valueKey]);
      }
      return arr;
    });
  }, [value, flatten]);

  const onChangeValue = (value: any, selectedOptions: any) => {
    if (!isArray(value) || !flatten) {
      return onChange?.(value, selectedOptions);
    }
    const flattenValue = value.map((item) => last(item));
    onChange?.(flattenValue as any, selectedOptions);
  };

  return (
    <Cascader
      showSearch
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder={dict('PLEASE_SELECT')}
      allowClear
      options={treeData}
      value={cascaderValue}
      onChange={onChangeValue}
      {...props}
    />
  );
};

export default EnumCascader;
