import { MEDIA_KEY_ENUM } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import { getAccountList } from '@/services/account';
import { Validator } from '@/utils/validator';
import { Select, SelectProps } from '@antd';
import { useCreation, useRequest } from 'ahooks';
import { isArray, isEmpty } from 'lodash';
import { Key } from 'react';

type AccountSelectProps = {
  filterOrgIds?: Key[];
  filterMediaIds?: MEDIA_KEY_ENUM | MEDIA_KEY_ENUM[];
} & SelectProps;

type AccountOption = {
  orgId?: Key;
  label: string;
  value: Key;
};
const AccountSelect = ({ filterOrgIds, filterMediaIds, ...props }: AccountSelectProps) => {
  const { user } = useAppContext();
  const { data, loading } = useRequest(
    async () => {
      const orgAccounts = await getAccountList(user?.bid);
      const accounts: AccountOption[] = [];
      //需过滤的平台
      const filterPlatforms = Validator.isNilEmpty(filterMediaIds)
        ? []
        : isArray(filterMediaIds)
          ? filterMediaIds
          : [filterMediaIds];

      orgAccounts?.forEach(({ orgId, accountDataList }) => {
        accountDataList
          .filter((v) => (isEmpty(filterPlatforms) ? true : filterPlatforms.includes(v.mediaId)))
          .forEach((v) => accounts.push({ label: v.name, value: v.id, orgId }));
      });
      return accounts;
    },
    { refreshDeps: [user?.bid, filterMediaIds] },
  );

  const options = useCreation(() => {
    if (isEmpty(filterOrgIds) || !filterOrgIds) return data;
    return data?.filter((v) => filterOrgIds.includes(Number(v.orgId)));
  }, [data, filterOrgIds]);

  return <Select loading={loading} options={options as any[]} {...props} />;
};

export default AccountSelect;
