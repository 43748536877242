import { dict } from '@/hooks/useChangeLocale';
import { SearchOutlined } from '@ant-design/icons';
import { Input, InputProps } from '@antd';
import { useDebounceFn } from 'ahooks';
import { trim } from 'lodash';

export const SearchInput = (props: InputProps & { onKeywordChange: (text: string) => void }) => {
  const { onKeywordChange, ...restProps } = props;

  const { run: searcInputChange } = useDebounceFn(onKeywordChange, {
    wait: 300,
  });

  return (
    <Input
      prefix={<SearchOutlined />}
      placeholder={dict('ACTION_SEARCH')}
      onInput={(e: any) => {
        e.persist();
        searcInputChange(trim(e.target?.value || undefined));
      }}
      {...restProps}
    />
  );
};
