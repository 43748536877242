import { HolderOutlined, MinusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Anchor, Checkbox, Empty, Input, Modal, Tooltip } from '@antd';
import { AnchorLinkItemProps } from 'antd/es/anchor/Anchor';
import { cloneDeep, isFunction, sortBy } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import style from './styles.less';
import { CustomColumnProps, DyColumn, DyColumnGroup } from './type';
import { useMemoizedFn } from 'ahooks';
import { isEmpty } from 'lodash';
import { dict } from '@/hooks/useChangeLocale';

const { Search } = Input;

const DragHandle = SortableHandle(() => (
  <div className={style.handle}>
    <HolderOutlined />
  </div>
));
const SortableItem = SortableElement<any>((props) => <div {...props} />);
const SortableBody = SortableContainer<any>((props) => <div {...props} />);

const getAnchor = (item, level: number): AnchorLinkItemProps => ({
  key: item.groupName,
  href: `#${item.groupKey}`,
  title: <span style={{ paddingLeft: level * 8 }}>{item.groupName}</span>,
});

export const renderTitle = (column: DyColumn<any>) => {
  if (isFunction(column.title)) {
    return column.title({});
  }
  return column.title;
};

export const CustomColumnModal = (props: CustomColumnProps) => {
  const {
    onCancel,
    tableColumnsConfig,
    defaultTableColumnsConfig,
    onConfigOK,
    visible,
    sorable,
    searchable,
    ...other
  } = {
    title: dict('CUSTOM_COLUMNS'),
    okText: dict('APPLY'),
    width: 600,
    className: `${style['c-modals-custom-column']} ${props.className ?? ''}`,
    ...props,
  };

  const [config, setConfig] = useState<DyColumnGroup[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const selectedCheckbox = useRef(null);
  const { fixedSelected, autoSelected } = useMemo(() => {
    let allSelect: DyColumn[] = [];
    config.forEach((item) => {
      if (item.subGroups?.length) {
        item.subGroups.forEach((_item) => {
          allSelect = [...allSelect, ..._item.columns];
        });
      } else {
        allSelect = [...allSelect, ...item.columns];
      }
    });

    return {
      fixedSelected: allSelect.filter((item) => !item.deselectable),
      autoSelected: allSelect
        .filter((item) => item.deselectable && item.show)
        .sort((prev, next) => prev.sort - next.sort),
    };
  }, [config]);

  useEffect(() => {
    if (!visible) {
      setSearchValue('');
      setConfig([]);
    } else {
      setConfig(cloneDeep(tableColumnsConfig));
    }
  }, [visible]);

  const clickOk = () => {
    onConfigOK(cloneDeep(config));
    clickCancel(undefined);
  };
  const clickCancel = (e) => {
    onCancel?.(e);
  };
  const onSearch = (input) => {
    setSearchValue(input);
  };
  const onChangeSearch = (input) => {
    if (!input.target.value && searchValue) onSearch('');
  };
  const onCheckChange = (e, column, oldConfig) => {
    if (!column.deselectable) return;
    column.show = e.target.checked;
    if (e.target.checked) {
      const prevSort = autoSelected.slice(-1, autoSelected.length)[0]?.sort ?? 0;
      column.sort = prevSort + 1;
    }
    setConfig([...oldConfig]);
  };
  const onCheckAllChange = (e, columns, oldConfig) => {
    const checked = e.target.checked;
    const prevSort = autoSelected.slice(-1, autoSelected.length)[0]?.sort ?? 0;
    const waitDisposeColumns = columns.filter((item) => item.deselectable && checked !== item.show);
    waitDisposeColumns.forEach((item, index) => {
      item.show = checked;
      if (checked) item.sort = prevSort + 1 + index;
    });
    setConfig([...oldConfig]);
  };
  const onReset = () => {
    setConfig(cloneDeep(defaultTableColumnsConfig));
  };
  const onClear = () => {
    autoSelected.forEach((item) => (item.show = false));
    setConfig([...config]);
  };
  const onDelete = (column) => {
    column.show = false;
    setConfig([...config]);
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newConfig = arrayMoveImmutable(autoSelected, oldIndex, newIndex).filter((el) => !!el);
      newConfig.forEach((item, index) => {
        item.sort = index + 1 + fixedSelected.length;
      });
      setConfig([...config]);
    }
  };

  const renderNode = useMemoizedFn((oldTableColumnsConfig: DyColumnGroup[], e: React.RefObject<any>) => {
    const anchors: Array<AnchorLinkItemProps> = [];
    const renderGroup = (groupConfig: DyColumnGroup) => {
      // 设置导航
      const existSub = !isEmpty(groupConfig.subGroups);
      const groupConfigs = existSub ? (groupConfig.subGroups as any) : [groupConfig];
      const groupNodes = groupConfigs
        .map((item, index) => {
          const filterSearch = item.columns.filter(
            (_item) => _item.configable && `${renderTitle(_item) ?? ''}`.indexOf(searchValue) >= 0,
          );
          const nodeItems = filterSearch.map((_item) => {
            return (
              <div className={style['node-item']} key={_item.key}>
                <Checkbox
                  disabled={!_item.deselectable}
                  checked={!_item.deselectable || _item.show}
                  onChange={(e) => onCheckChange(e, _item, oldTableColumnsConfig)}
                >
                  {renderTitle(_item)}
                </Checkbox>
              </div>
            );
          });
          const checkAllDisabled = filterSearch.every((_item) => !_item.deselectable);
          const checkAll = !filterSearch.some((_item) => !_item.show);
          const indeterminate = !checkAll && filterSearch.some((_item) => _item.show);

          const nodes = nodeItems.length ? (
            <div key={item.groupKey}>
              <div className={style['node-group-name']}>
                <Checkbox
                  disabled={checkAllDisabled}
                  indeterminate={indeterminate}
                  checked={checkAll}
                  onChange={(e) => onCheckAllChange(e, filterSearch, oldTableColumnsConfig)}
                >
                  {item.groupName}
                </Checkbox>
              </div>
              <div className={style['node-group-box']}>{nodeItems}</div>
            </div>
          ) : undefined;

          // 加到导航里面
          if (nodes && existSub) anchors.push(getAnchor(groupConfig, 1));

          return nodes;
        })
        .filter((item) => item);

      // 加到导航里面
      if (!isEmpty(groupNodes)) anchors.push(getAnchor(groupConfig, 1));
      return groupNodes;
    };
    const node = oldTableColumnsConfig
      .map((item) => {
        const groups = renderGroup(item);

        return groups.length ? (
          <div className={style['node-group']} key={`node-group-${item.groupKey}`} id={item.groupKey}>
            {/* <div className={style['node-classify-name']}>{item.groupName}</div> */}
            {groups}
          </div>
        ) : undefined;
      })
      .filter((item) => item);
    return {
      contentAnchor: (
        <Anchor
          showInkInFixed={false}
          affix={false}
          getContainer={() => e.current}
          onClick={(e) => e.preventDefault()}
          items={anchors}
        />
      ),
      contentNode: node.length ? <>{node}</> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
    };
  });

  const fixedSelectedList = useMemo(() => {
    return sortBy(fixedSelected, 'sort').map((item) => (
      <div className={style['item']} key={`fixedSelected-${item.key}`}>
        {renderTitle(item)}
      </div>
    ));
  }, [fixedSelected]);
  const autoSelectedList = useMemo(() => {
    return autoSelected.map((item, index) => (
      <SortableItem index={index} className={style['item']} key={`autoSelected-${item.key}`}>
        <DragHandle />
        <div className={style['name']}>{renderTitle(item)}</div>
        <div className={style['delete']}>
          <MinusCircleOutlined onClick={() => onDelete(item)} />
        </div>
      </SortableItem>
    ));
  }, [autoSelected]);

  const { contentAnchor, contentNode } = renderNode(config, selectedCheckbox);

  return visible ? (
    <Modal open={visible} {...other} onOk={clickOk} onCancel={clickCancel}>
      <div className={style['custom-column-wrapper']}>
        <div className={style['target-box']}>
          {searchable && (
            <Search
              className={style['search-input']}
              placeholder={dict('ACTION_SEARCH')}
              onSearch={onSearch}
              onChange={onChangeSearch}
              prefix={<SearchOutlined />}
              allowClear
            />
          )}
          <div className={style['content']}>
            <div className={style['tree']}>
              {/*{renderAnchor(tableColumnsConfig, selectedCheckbox)}*/}
              {contentAnchor}
            </div>
            <div className={style['all-checkbox']} ref={selectedCheckbox}>
              {/*{renderNode(config)}*/}
              {contentNode}
            </div>
          </div>
        </div>
        {sorable && (
          <div className={style['selected-box']}>
            <div className={style['info-bar']}>
              <div className={style['info']}>
                {dict('SELECTED_NUM_COLUMNS', { num: fixedSelected.length + autoSelected.length })}
              </div>
              <a className={style['operate']} onClick={onReset}>
                {dict('RESET_TO_DEFAULT')}
              </a>
              <a className={style['operate']} onClick={onClear}>
                {dict('CLEAR_EMPTY')}
              </a>
            </div>
            <div className={style['selected-list']}>
              <div className={style['fixed-list']}>{fixedSelectedList}</div>
              {!!fixedSelectedList.length && (
                <div className={style['tips']}>
                  <Tooltip title={dict('ABOVE_LIST_CANNOT_BE_MODIFIED')}>
                    <span>{dict('ABOVE_LIST_CANNOT_BE_MODIFIED')}</span>
                  </Tooltip>
                </div>
              )}
              <SortableBody
                useDragHandle
                helperClass={style['custom-column-row-dragging']}
                onSortEnd={onSortEnd}
                className={style['auto-list']}
              >
                {autoSelectedList}
              </SortableBody>
            </div>
          </div>
        )}
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default CustomColumnModal;

export function arrayMoveMutable(array, fromIndex, toIndex) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable(array, fromIndex, toIndex) {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}
