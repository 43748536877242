import { Question } from '@/icons';
import { Tooltip, Typography } from '@antd';

type IndicatorTitleProps = {
  title: string;
  tip: string;
};
export const IndicatorTitle: React.FC<IndicatorTitleProps> = ({ title, tip }) => {
  return (
    <Typography.Text>
      {title}{' '}
      <Tooltip title={tip}>
        <Question />
      </Tooltip>
    </Typography.Text>
  );
};
