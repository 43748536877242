import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { OrganizationEnum } from '@/pages/system/organization/const.var';
import { getOrganizationList } from '@/services/organization';
import { OrganizationModel } from '@/services/organization/type';
import { Validator } from '@/utils/validator';
import { TreeSelect, TreeSelectProps, Typography } from '@antd';
import { useCreation, useRequest } from 'ahooks';
import { Function1, difference, get, isEmpty, max, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';

type OrgTreeSelectProps = {
  allowOrgType?: OrganizationEnum[];
  triggerChildrenChecked?: boolean;
  organizationList?: OrganizationModel[];
} & TreeSelectProps;
export const OrgTreeSelect: React.FC<OrgTreeSelectProps> = ({
  allowOrgType,
  triggerChildrenChecked,
  organizationList,
  ...props
}) => {
  const [checkedIds, setCheckedIds] = useState(props.value ?? []); //搜索下拉框里选父级需要带动子级勾选，子级不需要带动上级勾选
  const { user } = useAppContext();
  const { data: organizations, loading } = useRequest(async () => organizationList || getOrganizationList(user?.bid), {
    refreshDeps: [user, organizationList],
  });

  const [treeData, treeDataMap] = useCreation(() => {
    if (Validator.isNilEmpty(organizations)) return [[], {}];
    const organizationMap = organizations.reduce((map, cur) => {
      map[cur.id] = cur;
      return map;
    }, {});
    const cache = {};
    const roots: any[] = [];
    const typeDepth: number = max(allowOrgType) ?? OrganizationEnum.Talent;

    organizations
      ?.filter((org) => org)
      .filter((org) => org.type <= typeDepth)
      .sort((v1, v2) => v1.type - v2.type)
      .forEach((org) => {
        const label = org.name + (org.code ? `  (${org.code})` : '');
        const curOrg = {
          ...org,
          value: org.id,
          title: (
            <Typography.Text title={label} style={{ maxWidth: 180, display: 'block' }} ellipsis>
              {label}
            </Typography.Text>
          ),
          selectable: isEmpty(allowOrgType) ? true : allowOrgType?.includes(org.type),
        };
        cache[org.id] = curOrg;
        if (org.parentId) {
          if (!cache[org.parentId]) {
            cache[org.parentId] = get(organizationMap, org.parentId);
          }
          if (cache[org.parentId]) {
            cache[org.parentId].children = cache[org.parentId].children || [];
            cache[org.parentId].children.push(curOrg);
          }
        } else {
          roots.push(curOrg);
        }
      });
    return [roots, cache];
  }, [organizations, JSON.stringify(allowOrgType)]);

  const getChildrenNode = (orgId, nodeIds: any[] = []) => {
    if (!orgId) return nodeIds;
    const curNode = treeDataMap[orgId];
    nodeIds.push(orgId);
    if (!isEmpty(curNode?.children)) {
      for (const node of curNode.children) {
        getChildrenNode(node.id, nodeIds);
      }
    }
    return nodeIds;
  };

  const getParentNode = (orgId, nodeIds: any[] = []) => {
    if (!orgId) return nodeIds;
    const curNode = treeDataMap[orgId];
    nodeIds.push(orgId);
    if (curNode.parentId) {
      getParentNode(curNode.parentId, nodeIds);
    }
    return nodeIds;
  };
  const triggerChildrenConfig: TreeSelectProps = triggerChildrenChecked
    ? {
        treeDefaultExpandAll: true,
        multiple: true,
        treeCheckable: true,
        treeCheckStrictly: true,
        maxTagCount: 'responsive',
        onClear: () => setCheckedIds([]),
        // showCheckedStrategy: TreeSelect.SHOW_PARENT,

        onChange: (value, _, extra) => {
          const { checked, triggerValue } = extra;
          const childrenNodes = getChildrenNode(triggerValue);
          const parentNodes = getParentNode(triggerValue);
          const curIds = value.map((v) => v.value);
          const checkValue = checked
            ? uniq([...curIds, ...childrenNodes])
            : difference(curIds, childrenNodes, parentNodes);
          setCheckedIds(checkValue);
        },
      }
    : {};

  useEffect(() => {
    if (!triggerChildrenChecked) return;
    const onChange = props.onChange as Function1<[], undefined>;
    onChange?.(checkedIds);
  }, [checkedIds, triggerChildrenChecked]);

  return (
    <TreeSelect
      showSearch
      loading={loading}
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder={dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('ORG_STRUCTURE') })}
      treeLine
      allowClear
      treeData={treeData}
      filterTreeNode={(inputV, node: any) => {
        if (!inputV) return true;
        const v = inputV.toLowerCase();
        return node.name.toLowerCase().includes(v) || (node.code || '').toLowerCase().includes(v);
      }}
      {...props}
      {...triggerChildrenConfig}
    />
  );
};
